import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';
import SearchRounded from '@material-ui/icons/SearchRounded';

const useStyles = makeStyles({
  root: {
    width: "100%",
    position:"fixed",
    backgroundColor: "#f3f3f3",
    top: "auto",
    bottom: "0"
  },
});

export default function NavBottom(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation value={value} onChange={handleChange} className={classes.root} style={{top: "auto", bottom: "0",width:"100%"}}>
      <BottomNavigationAction showLabel={true} label="Search" value="Search" icon={<SearchRounded />} onClick={() => {props.loadNewComponent("NestedList");props.load(2);}} />
      <BottomNavigationAction showLabel={true} label="Account" value="Account" icon={<AccountCircleRounded />} onClick={() => {props.loadNewComponent("SignUp");}} />
    </BottomNavigation>
  );
}
