import React, {useState, useEffect} from 'react';
import logo from './mdxp-logo.png';
import MainMenu from './MainMenu';
import DynamicLoader from './DynamicLoader';
//https://github.com/darwin-education/resium/tree/master
//https://cesium.com/blog/2018/03/05/integrating-cesium-and-react/
//https://github.com/traversals-analytics-and-intelligence/example-react-cesium-library
//https://resium.darwineducation.com/getting_started


export const Home = () =>  {
  
  const tab = useState(0);
  const [manufacturers, getManufacturers] = useState([]);
  const [result, setResult] = useState([{"name":"Waiting"}]);
  const [newComponent, getNewComponent] = useState("Lister");
  const [connected, setConnectedStatus] = useState("Waiting");
  function callApiParent(typenumber) {
      fetch("https://account.mdxp.io/adminCust8ter/db-interaction/display.php?type=" + typenumber).then(res=>res.json().then(getManufacturers));
  }
  function callApiForm(frmdetails) {
    const requestOptions = {
        method: 'POST',
        headers: {          
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
        },
        mode:"cors",
        body: JSON.stringify(frmdetails)
    };
    fetch('https://account.mdxp.io/db-interaction/display.php', requestOptions).then(res => res.json().then(setResult));    
  }
  function callNewComponent(target) {
      getNewComponent(target);
  }
  function setUserStatus(valeur) {
    setConnectedStatus(valeur);
  }
  const menus = {
    slide: {buttonText: 'Slide', items: 1},
    stack: {buttonText: 'Stack', items: 1},
    elastic: {buttonText: 'Elastic', items: 1},
    bubble: {buttonText: 'Bubble', items: 1},
    push: {buttonText: 'Push', items: 2},
    pushRotate: {buttonText: 'Push Rotate', items: 2},
    scaleDown: {buttonText: 'Scale Down', items: 2},
    scaleRotate: {buttonText: 'Scale Rotate', items: 2},
    fallDown: {buttonText: 'Fall Down', items: 2},
    reveal: {buttonText: 'Reveal', items: 1}
  };

  return (      
          <div id="outer-container" style={{ backgroundColor: '#ffffff', height: '100vh' }}>            
            <MainMenu menus={menus} load={callApiParent} style={{ backgroundColor: '#111111', height: '100vh' }}/>            
            <main id="page-wrap" style={{ backgroundColor: '#ffffff', height: '100vh' }}>                
                <div>
                  {newFunction()}
                  <h1>Welcome to MDmeets</h1>                   
                  <DynamicLoader load={callApiParent} newComponent={newComponent} loadNewComponent={callNewComponent} tab={manufacturers} loadApiForm={callApiForm} loadresult={result} setUserStatus={setUserStatus} currentStatus={connected} />
                </div>
                <div id="addBtn">ADD TO HOME</div>
            </main>
            <div className="connected">{connected}</div>
            <DynamicLoader load={callApiParent} newComponent={"NavBottom"} loadNewComponent={callNewComponent} loadApiForm={callApiForm} loadresult={result} setUserStatus={setUserStatus} currentStatus={connected} />
          </div>       
  ); 
  function newFunction() {
    return <img src={logo} className="" alt="logo" onClick={Dje} />;
  }
  function Dje(){
    console.log('NICE');   
  }
}