import React, { Component } from "react";

class DynamicLoader extends Component {

  render() {
    const COMPONENTS = {
      NavBottom: require('./NavBottom'),
      Lister: require('./Lister'),
      NestedList: require('./Lister'),
      SignUp: require('./SignUp'),
      SignIn: require('./SignIn'),
    }   
    if(this.props.newComponent && COMPONENTS[this.props.newComponent]) {
        let Component = COMPONENTS[this.props.newComponent].default;   
        //const dynamicProps = Object.entries(this.props).map(([key,value])=>{return ({" "} {key}={value});});
        //return <Component {...dynamicProps} />;
        //console.log(this.props.loadresult);
        return <Component tab={this.props.tab} load={this.props.load} newComponent={this.props.newComponent} loadNewComponent={this.props.loadNewComponent} loadApiForm={this.props.loadApiForm} loadresult={this.props.loadresult} setUserStatus={this.props.setUserStatus} currentStatus={this.props.currentStatus} />           
    }
  }
}

export default DynamicLoader;