import React, {useState, useEffect} from 'react';
export var MenuWrap = (props) =>  {
    const [hidden, setHidden] = useState(false);
    function componentWillReceiveProps(nextProps) {
        const sideChanged = props.children.props.right !== nextProps.children.props.right;        
        if (sideChanged) {
            setHidden(true);
    
          setTimeout(() => {
            show();
          }, React.wait);
        };
    };
    function show() {
        setHidden(false);
    };
    
    let style;

    if (hidden) {
        style = {display: 'none'};
    }

    return (
        <div style={style} className={props.side}>
        {props.children}
        </div>
    );
};
