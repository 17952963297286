import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles  } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
const AntSwitch = withStyles ((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp (props) {
  const classes = useStyles();

  //console.log(props.loadresult);
  const results = props.loadresult || [{"name":"Waiting"}];
  const currentStat = props.currentStatus || "false";   
  
  const [firstName, setfName] = useState('');
  const [lName, setlName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');    
  const [action, setAction] = React.useState({
    action: true,
  });
  const handleChange = (event) => {
    setAction({ ...action, [event.target.name]: event.target.checked });
  };
  
  const submitValue = () => {
      const frmdetails = {
          't' : "createUserAppAccount",
          'name' : name,
          'action' : action,
          'firstName' : firstName,
          'lName' : lName,
          'Email' : email
      }
      
      props.loadApiForm(frmdetails);
     
      const finalval = results.map((item, i) => {
        if (item.name=="Pending"){
          props.setUserStatus("true");
        }
        console.log(currentStat);
      });
  }
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
  <div>RESULT {results && results.map((item, i) => <div key={i.toString()}>{item.name}{props.setUserStatus(item.name)} CURRENT STAT : {currentStat}</div>)}</div>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                fullWidth
                id="name"
                label="name"
                onChange={e => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="firstName"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                onChange={e => setfName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={e => setlName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} component="label" container alignItems="center" spacing={1}>
              <Grid item>Distributor</Grid>
              <Grid item>
                <AntSwitch checked={action.action} onChange={handleChange} name="action" />
              </Grid>
              <Grid item>Manufacturer</Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
            {/*<Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            */}
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            /* type="submit" */
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitValue}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              {/* <Link href="#" onClick={() => {props.loadNewComponent("SignIn");}} variant="body2">
                Already have an account? Sign in
              </Link> */}
              <div onClick={() => {props.loadNewComponent("SignIn");}} variant="body2">
                Already have an account? Sign in
              </div>
            </Grid>
          </Grid>
        </form>
       
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}