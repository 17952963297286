import React from 'react';
import {Home} from './Home';
import {ThemeContext, themes} from './theme-context';
import ThemeTogglerButton from './theme-toggler-button';
class App extends React.Component {
    constructor(props) {
      super(props);
  
      this.toggleTheme = () => {
        this.setState(state => ({
          theme:
            state.theme === themes.dark
              ? themes.light
              : themes.dark,
        }));
      };
  
      // L’état local contient aussi la fonction de mise à jour donc elle va
      // être passée au fournisseur de contexte
      this.state = {
        theme: themes.light,
        toggleTheme: this.toggleTheme,
      };
    }
  
    render() {
      // L’état local entier est passé au fournisseur
      return (
        <ThemeContext.Provider value={this.state}>
         {/*<Content />*/} 
          <Home />
        </ThemeContext.Provider>

        
      );
    }
}
function Content() {
    return (
      <div>
        <ThemeTogglerButton />        
      </div>
    );
}
export default App;