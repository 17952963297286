import React, {useState,useContext} from 'react';
import BurgerMenu from 'react-burger-menu';
import {MenuWrap} from './MenuWrap';
import {Buttoncool} from './Button';
//import {slide as Menu} from 'react-burger-menu';

// make a new context
const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false)
  
  return (
    <MyContext.Provider value={{
      isMenuOpen: menuOpenState,
      toggleMenu: () => setMenuOpenState(!menuOpenState),
      loadering: () => props.load(2),
      stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen) 
    }}> 
      {props.children}
    </MyContext.Provider>
  )
}
// create a button that calls a context function to set a new open state when clicked
const Button = () => {
  const ctx = useContext(MyContext)
  return (
    <button onClick={ctx.toggleMenu}>Toggle menu</button>
  )
}

const Navigation = () => {
  const ctx = useContext(MyContext)
  const Menu = BurgerMenu["push"];
  return (
    <MenuWrap wait={20} side='left'>
      <Menu id="push" pageWrapId={'page-wrap'} outerContainerId={'outer-container'}        
        isOpen={ctx.isMenuOpen}
        onStateChange={(state) => ctx.stateChangeHandler(state)}>
          <Buttoncool key="0"  name="Manufacturers not qualified" jeclick={() => {ctx.loadering();ctx.toggleMenu();}} />
        </Menu>
      </MenuWrap>
    
  )
}
// default export here
const MainMenu = (props) => {
  return (
    <MyProvider load={props.load}>
      <div>
        {/*<Button />*/}
        <Navigation />
      </div>
    </MyProvider>
  )
}

export default MainMenu;

/* const MainMenu = (props) =>  {
    const ctx = useContext(MyContext)
    const [currentMenu, setCurrentMenu] = useState('push');
    const [side, setSide] = useState('left');
    const [state, setState] = useState(0);
    const [menuOpen, setMenuState] = useState(false);   
    function changeMenu(menu) {
        setCurrentMenu(menu);
    }    
    function changeSide(side) {
        setSide(side);
    }    
    function getItems() {
        let items;    
        switch (props.menus[currentMenu].items) {
          case 1:
            items = [
              <a key="0" href=""><i className="fa fa-fw fa-star-o" /><span>Favorites</span></a>,
              <a key="1" href=""><i className="fa fa-fw fa-bell-o" /><span>Alerts</span></a>,
              <a key="2" href=""><i className="fa fa-fw fa-envelope-o" /><span>Messages</span></a>,
              <a key="3" href=""><i className="fa fa-fw fa-comment-o" /><span>Comments</span></a>,
              <a key="4" href=""><i className="fa fa-fw fa-bar-chart-o" /><span>Analytics</span></a>,
              <a key="5" href=""><i className="fa fa-fw fa-newspaper-o" /><span>Reading List</span></a>
            ];
            break;
          case 2:
            items = [
              <Buttoncool key="0"  name="Manufacturers not qualified" jeclick={() => {props.load(2); setState(1);}} />
            ];
            break;
          default:
            items = [
              <a key="0" href=""><i className="fa fa-fw fa-star-o" /><span>Favorites</span></a>,
              <a key="1" href=""><i className="fa fa-fw fa-bell-o" /><span>Alerts</span></a>,
              <a key="2" href=""><i className="fa fa-fw fa-envelope-o" /><span>Messages</span></a>,
              <a key="3" href=""><i className="fa fa-fw fa-comment-o" /><span>Comments</span></a>,
              <a key="4" href=""><i className="fa fa-fw fa-bar-chart-o" /><span>Analytics</span></a>,
              <a key="5" href=""><i className="fa fa-fw fa-newspaper-o" /><span>Reading List</span></a>
            ];
        }
    
        return items;
      }
    
    function getMenu() {
        const Menu = BurgerMenu[currentMenu];
    
        return (
          <MenuWrap wait={20} side={side}>
            <Menu id={currentMenu} pageWrapId={'page-wrap'} outerContainerId={'outer-container'} right={side === 'right'} isOpen={ctx.isMenuOpen} onStateChange={(state) => ctx.stateChangeHandler(state)}>
              {getItems()}
              {buttons}
              <ButtonX />
            </Menu>
          </MenuWrap>
        );
    }   
    const buttons = Object.keys(props.menus).map((menu) => {
        return (
            <a key={menu}
            className={classNames({'current-demo': menu === currentMenu})}
            onClick={() => changeMenu(menu)}>
            {props.menus[menu].buttonText}
            </a>
        );
    });

    return (getMenu());
    
} */
