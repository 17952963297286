import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
/* import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder'; */

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 600,
      margin: "2rem auto",
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

export default function NestedList(props) {
//export const NestedList = ({tab}) =>  {
  const classes = useStyles();
  const currentStatus = props.currentStatus;   
  const [open, setOpen] = React.useState(true);   
  const listItems = (currentStatus && currentStatus!="Connected") ? <h2>You need to connect first</h2> : props.tab.map((item, i) => <ListItem button key={i.toString()}>{item.name}</ListItem>);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    
    <List
      component="div"
      aria-labelledby="nested-list-subheader-bis"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader-bis">
          Click on one of the icon below
        </ListSubheader>
      }
      className={classes.root}
    >     
        {listItems}
    </List>        
  );
}
